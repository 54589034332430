<template>
  <div class="academic-years">
    <lenon-list-page
      title="Students"
      :columns="columns"
      :rows="students"
      :table-loading="tableLoading"
      :show-create="false"
      :show-search="true"
      :enable-multi-select="true"
      :min-height="students.length===1?180:0"
      :show-profile-photo="true"
      @onRowSelect="handleRowSelect"
    >
      <template slot="right-extra-header-actions">
        <div
          v-responsive="$store.getters['app/largeScreen']"
          class="d-flex align-self-center align-items-start"
          style="margin-bottom: -18px;"
        >
          <lenon-select
            v-model="selectedClassId"
            :options="classes"
            label-name="name"
            value-name="id"
            placeholder="Select Class"
            class="mr-2"
            @input="fetchStudents"
          />
  <lenon-button
      v-if="!isFreemium"
            variant="outline-warning"
            icon="UserIcon"
            class="mr-2"
            label="Generate IDs"
            tool-tip-text="Generate ID Cards"
            @onClick="generateIdCards()"
          />
          <!--          <lenon-button-->
          <!--            variant="outline-warning"-->
          <!--            icon="DownloadCloudIcon"-->
          <!--            class="mr-2"-->
          <!--            :icon-only="true"-->
          <!--            tool-tip-text="Download students data"-->
          <!--          />-->
          <!--          <lenon-button-->
          <!--            variant="outline-warning"-->
          <!--            icon="UploadCloudIcon"-->
          <!--            class="mr-2"-->
          <!--            :icon-only="true"-->
          <!--            tool-tip-text="Import students excel data"-->
          <!--          />-->
          <lenon-button
            icon="UserPlusIcon"
            label="Register"
            tool-tip-text="Register students"
            class=""
            @onClick="showStudentModal()"
          />
        </div>
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="d-flex align-self-center align-items-start"
        >
          <lenon-dropdown left>
            <lenon-dropdown-item
              icon="UserPlusIcon"
              text="Register"
              @click="showStudentModal()"
            />
            <lenon-dropdown-item
                v-if="!isFreemium"
                icon="UserIcon"
                text="Generate IDs"
                @click="generateIdCards()"
            />
          </lenon-dropdown>
        </div>
      </template>
      <template slot="table-header">
        <div
          v-responsive="$store.getters['app/smallScreen']"
          class="col-md-6"
        >
          <lenon-select
            v-model="selectedClassId"
            :options="classes"
            label-name="name"
            value-name="id"
            placeholder="Select Class"
            @input="fetchStudents"
          />
        </div>
      </template>
      <template #first_name="{row}">
        {{ row.item.first_name }} {{ row.item.middle_name }} {{ row.item.last_name }}
      </template>
      <!--      <template #photo="{row}">-->
      <!--        <b-avatar-->
      <!--          :variant="row.item.gender===0?'light-primary':'light-success'"-->
      <!--          size="40"-->
      <!--          :src="getFullPath(row.item.photo)"-->
      <!--        />-->
      <!--      </template>-->
      <template #actions="{row}">
        <lenon-dropdown>
          <!--          <b-dropdown-item-->
          <!--            @click="showPreviewModal(row.item)"-->
          <!--          >-->
          <!--            <feather-icon-->
          <!--              icon="EyeIcon"-->
          <!--              class="mr-50"-->
          <!--            />-->
          <!--            <span> View</span>-->
          <!--          </b-dropdown-item>-->
          <b-dropdown-item
            @click="showEditModal(row.item)"
          >
            <feather-icon
              icon="Edit2Icon"
              class="mr-50"
            />
            <span> Edit</span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="row.status !==1"
            @click="confirmDelete(row.item)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-50"
            />
            <span> Delete</span>
          </b-dropdown-item>
        </lenon-dropdown>
      </template>
      <template #status="{row}">
        <lenon-button
          :icon-only="true"
          :variant="row.item.status?'flat-success':'flat-danger'"
          :icon="row.item.status?'UserCheckIcon':'UserXIcon'"
          :tool-tip-text="row.item.status?'Active, click to set inactive':'Inactive, click to set active'"
          @onClick="toggleStudentStatus(row.item)"
        />
      </template>
      <template #gender="{row}">
        <b-badge
          :icon-only="true"
          :variant="row.item.gender===0?'light-primary':'light-success'"
        >
          {{ row.item.gender===0?'Male':'Female' }}
        </b-badge>
      </template>
    </lenon-list-page>
    <student-registration
      :modal-opened="studentsModalOpened"
      @modalClosed="closeModal('student')"
    />
    <validation-observer
      ref="feePaymentForm"
      v-slot="{invalid}"
    >
      <lenon-modal
        title="Generate Student ID Cards"
        :show="idCardModalOpened"
        :show-overlay="generatingIds"
        size="md"
        @onClose="idCardModalOpened=false"
      >
        <div class="row">
          <div class="col-12">
            You are about generate student id cards, please confirm.
          </div>
        </div>
        <div
          v-if="selectedStudents.length>0"
          class="row mb-1"
        >
          <div class="col-12 mt-1">
            <b-form-checkbox v-model="generateForSelectedStudents">
              Generate only for selected students
            </b-form-checkbox>
          </div>
        </div>
        <!--        <lenon-multi-select-->
        <!--          v-model="selectedClasses"-->
        <!--          label-name="name"-->
        <!--          value-name="id"-->
        <!--          :options="classes"-->
        <!--          label="Classes"-->
        <!--          rules="required"-->
        <!--        />-->

        <template slot="modal-actions">
          <div class="float-right">
            <lenon-button
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="idCardModalOpened=false"
            />
            <lenon-button
              label="Generate"
              :disabled="invalid"
              :loading="generatingIds"
              loading-text="Loading..."
              @onClick="generate()"
            />
          </div>
        </template>
      </lenon-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BAvatar, BDropdownItem, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import StudentRegistration from '@/views/students/registration/Registration.vue'
import { FETCH_STUDENTS_Q } from '@/graphql/queries'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import confirm from '@/lenon/mixins/confirm'
import { DELETE_STUDENT_M, GENERATE_STUDENT_ID_CARDS_M, TOGGLE_STUDENT_STATUS_M } from '@/graphql/mutations'
import LenonModal from '@/lenon/components/LenonModal.vue'
import { ValidationObserver } from 'vee-validate'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'

export default {
  name: 'StudentsList',
  components: {
    LenonDropdownItem,
    LenonModal,
    LenonDropdown,
    StudentRegistration,
    LenonSelect,
    LenonListPage,
    LenonButton,
    BAvatar,
    BDropdownItem,
    BBadge,
    ValidationObserver,
    BFormCheckbox,
  },
  mixins: [showToast, confirm],
  data() {
    return {
      selectedClassId: null,
      studentsModalOpened: false,
      tableLoading: false,
      idCardModalOpened: false,
      generatingIds: false,
      generateForSelectedStudents: false,
      selectedStudents: [],
      selectedClasses: [],
    }
  },
  computed: {
    isFreemium() {
      return this.$store.getters['auth/subscription']?.freemium
    },
    students() {
      return this.$store.getters['students/students']
    },
    classes() {
      return this.$store.getters['termsClasses/termClasses']
    },
    classId() {
      return this.$store.getters['students/selectedClassId']
    },
    columns() {
      const show = this.$store.getters['app/isLargeScreen']
      return [
        {
          key: 'multiselect',
          label: '',
        },
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          key: 'student_id',
          label: 'Student ID',
          sortable: true,
        },
        {
          key: 'first_name',
          label: 'Full Name',
          sortable: show,
        },
        {
          key: 'gender',
          label: 'Gender',
          sortable: show,
        },
        {
          key: 'age',
          label: 'Age',
          sortable: show,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: show,
        },
        {
          key: 'created_at',
          label: 'Registration Date',
          sortable: show,
        },
        {
          key: 'actions',
          label: 'Action',
        },
      ]
    },
  },
  mounted() {
    this.selectedClassId = this.classId
  },
  methods: {
    generateIdCards() {
      if(this.selectedStudents.length>0){
        let students = this.selectedStudents.map(m => m.id)
        this.tableLoading = true
        this.$http.post("system/generate-id-cards", { students }).then(res => {
          this.downloadFile(res.data, 'Students ID Cards')
        }).catch(err => {
          this.showError('Failed to generate id cards')
        }).finally(() => {
          this.tableLoading = false
        })
      }else{
        this.showInfo("Please select students to generate id cards")
      }

    },
    handleRowSelect(items) {
      this.selectedStudents = items
    },
    showIdCardModal() {
      if (this.selectedClassId) {
        this.idCardModalOpened = true
      } else {
        this.showInfo('Please select a class')
      }
    },
    generate() {
      if (this.generateForSelectedStudents) {
        this.generateCards({ students: this.selectedStudents.map(st => st.id) })
      } else {
        this.generateCards({ class_id: this.selectedClassId })
      }
    },
    generateCards(input) {
      this.generatingIds = true
      this.$apollo.mutate({
        mutation: GENERATE_STUDENT_ID_CARDS_M, variables: input,
      }).then(res => {
        this.generatingIds = false
        if (!res.errors) {
          this.downloadFile(res.data.generateIdCard, 'Id_Card')
        }
      }).catch(() => {
        this.showError('Failed to generate id card')
        this.generatingIds = false
      })
    },
    downloadFile(url, name) {
      const link = document.createElement('a')
      link.href = `${process.env.VUE_APP_STORAGE_PATH}/${url}`
      link.target = 'blank'
      link.setAttribute('download', name) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    toggleStudentStatus(student) {
      if (!student) {
        this.showInfo('Please select a student')
        return
      }
      this.tableLoading = true
      this.$apollo.mutate({
        mutation: TOGGLE_STUDENT_STATUS_M,
        variables: {
          input: {
            id: student.id,
            status: !student.status,
          },
        },
      }).then(res => {
        this.tableLoading = false
        this.showSuccess('Updated Successfully')
        this.$store.commit('students/updateStudent', res.data.toggleStudentStatus)
      }).catch(() => {
        this.showError('Failed to update student')
        this.tableLoading = false
      })
    },
    showPreviewModal(student) {

    },
    showEditModal(student) {
      if (student) {
        this.$store.commit('students/setStudent', student)
        this.studentsModalOpened = true
      }
    },
    confirmDelete(student) {
      this.confirmAction('Are you sure you want to delete this student?').then(res => {
        if (res) {
          this.deleteStudent(student)
        }
      })
    },
    getFullPath(photo) {
      return `${process.env.VUE_APP_STORAGE_PATH}/${photo}`
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'student':
          this.studentsModalOpened = false
          break
      }
    },
    showStudentModal() {
      this.studentsModalOpened = true
      this.$store.commit('students/setStudent', {})
    },
    // eslint-disable-next-line camelcase
    fetchStudents(classId) {
      this.$store.commit('students/setSelectedClass', classId)
      if (!classId) {
        this.$store.commit('students/setStudents', [])
        return
      }
      this.tableLoading = true
      this.$apollo.query({ query: FETCH_STUDENTS_Q, variables: { class_id: classId, from_web: true } })
        .then(res => {
          this.$store.commit('students/setStudents', res.data.students)
          this.tableLoading = false
        }).catch(err => {
          this.tableLoading = false
        })
    },
    deleteStudent(student) {
      if (!student) {
        this.showInfo('Please select a student')
        return
      }
      this.tableLoading = true
      this.$apollo.mutate({
        mutation: DELETE_STUDENT_M, variables: { id: student.id },
      }).then(() => {
        this.tableLoading = false
        this.showSuccess('Deleted Successfully')
        this.$store.commit('students/removeStudent', student.id)
      }).catch(() => {
        this.showError('Failed to delete student')
        this.tableLoading = false
      })
    },
  },
}
</script>
